import React from 'react';
import cx from 'classnames';

import FullWidthSection from '~/components/static/full-width-section';
import HeroInputForm from '~/components/static/home/hero-input-form';
import { useLawn } from '~/features/lawns/lawn-actions';
import { useSubscriptionsForLawn } from '~/features/subscriptions/subscription-actions';
import { useFormAnswers } from '~/hooks/use-form-answers';
import LoadingIndicator from '~/components/loading-indicator';
import Button from '~/components/button';
import {
  lawnQuestionnaireRoute,
  newLawnRoute,
  planDetailsRoute,
} from '~/routes';
import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';

import styles from '~/components/static/home/address-entry-section/address-entry-section.module.scss';

const AddressEntrySection = ({
  type = AutocompleteTypes.FULL_ADDRESS,
  initialValues = {},
}) => {
  /**
   * Cases to cover:
   * 1. Non-logged in user (or logged in user with NO Lawn) -> Go to `/lawns/new` (current experience)
   * 2. Logged in user with Lawn but no quiz answers -> Go to Quiz
   * 3. Logged in user with Lawn and completed quiz -> Go to Plan Selection page
   * 4. Logged in user with a Lawn Subscription -> Go to My Plan page
   *
   * Bonus: is a user is a non-Lawn Subscriber, we should NOT show the "start over with a new address" option
   */

  const { lawn, isLoading: isLoadingLawn } = useLawn();
  const {
    isCurrentSubscriber,
    isCurrentLawnSubscriber,
    isLoading: isLoadingSubs,
  } = useSubscriptionsForLawn(lawn?.uuid);
  const { formAnswers, isLoading: isLoadingAnswers } = useFormAnswers();

  const hasCompletedLawnQuiz = formAnswers?.LAWN_FUNNEL?.length > 0;
  const isNonLawnSubscriber = isCurrentSubscriber && !isCurrentLawnSubscriber;

  const isLoading = isLoadingLawn || isLoadingSubs || isLoadingAnswers;

  // HeroInputForm handles logic for leads with no Lawn,
  // current Lawn Subscribers, and non-Lawn Subscribers.
  // So we only need to handle returning leads here.
  let buttonText = null;
  let buttonRoute = null;
  let Subheading = () => <>Sunday custom lawn plans now start at $55</>;
  if (!isCurrentLawnSubscriber && lawn && hasCompletedLawnQuiz) {
    // Returning lead who has completed quiz
    buttonText = 'See my plan';
    buttonRoute = (lawnUuid) => planDetailsRoute(lawnUuid);
    Subheading = () => <span>Your custom lawn plan is ready!</span>;
  } else if (!isCurrentLawnSubscriber && lawn) {
    // Returning lead who has NOT completed quiz
    buttonText = 'Build my custom plan';
    buttonRoute = (lawnUuid) => lawnQuestionnaireRoute(lawnUuid);
    Subheading = () => <span>Want to pick up where you left off?</span>;
  }

  return (
    <FullWidthSection
      first
      className={styles.addressEntrySection}
      id="homepage-hero-section"
    >
      <div className={styles.content}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <h1
              id="homepage-hero-header"
              className={cx({
                [styles.leftAligned]: type === AutocompleteTypes.ZIP_CODE,
              })}
            >
              Save $100s and get a greener lawn
            </h1>
            <p
              className={cx(styles.subheading, {
                [styles.leftAligned]: type === AutocompleteTypes.ZIP_CODE,
              })}
              id="homepage-hero-subheader"
            >
              <Subheading />
            </p>
            <div className={styles.addressFormContainer}>
              {buttonText && buttonRoute ? (
                <div className={styles.mainCtaButtonContainer}>
                  <p>
                    <Button
                      to={buttonRoute(lawn?.uuid)}
                      variant="yellow"
                      fullWidthMobile
                      style={{ minWidth: '30rem' }}
                    >
                      {buttonText}
                    </Button>
                  </p>
                  {!isNonLawnSubscriber && (
                    <p>
                      <Button
                        variant="link"
                        to={newLawnRoute()}
                        className={styles.mainCtaRestartLink}
                        fullWidthMobile
                      >
                        Start new lawn analysis
                      </Button>
                    </p>
                  )}
                </div>
              ) : (
                <>
                  {type === AutocompleteTypes.ZIP_CODE && (
                    <p className={styles.zipcodeGetStarted}>
                      {/* <img
                        src="/images/zipcode-funnel/get-started.svg"
                        alt="Mouse arrow clicking on a box"
                        width="28"
                        height="34"
                      />{' '} */}
                      Get started: zip code
                    </p>
                  )}

                  <HeroInputForm
                    variant="light"
                    analyticsId="homepage-hero-input"
                    type={type}
                    initialValues={initialValues}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className={styles.heroBadgeWrapper} id="hero-badge"></div>
    </FullWidthSection>
  );
};

export default AddressEntrySection;
