import React, { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import FullWidthSection from '~/components/static/full-width-section';
import { lawnProblemRoute } from '~/routes';
import { problemAtom } from '~/components/static/home/start-with-problem-section/start-with-problem-section';
import Button from '~/components/button';
import {
  DeprecatedEventType,
  EventType,
  analyticsBeacon,
} from '~/utils/analytics';
import { START_WITH_PROBLEM_OPTIONS } from '~/components/static/home/start-with-problem-section/start-with-problem-content';

import styles from '~/components/static/home/start-with-problem-section/start-with-problem-section.module.scss';

const ImageButtonsStartWithProblemSection = () => {
  const [, setValue] = useAtom(problemAtom);
  const navigate = useNavigate();

  const handleClick = async (problemSlug) => {
    const problem = START_WITH_PROBLEM_OPTIONS?.[problemSlug];
    setValue(problem);
    analyticsBeacon.emit(EventType.LAWN_PROBLEM_SELECTED, {
      problemSlug,
      problemLabel: problem.label,
    });
    navigate(lawnProblemRoute());
  };

  return (
    <FullWidthSection
      first
      className={cx(styles.startWithProblemSection, styles.noBackgroundImage)}
      id="homepage-hero-section-problem-images"
    >
      <div className={styles.content}>
        <div>
          <h1>Weeds? Bare spots? Pets? No problem.</h1>
          <p className={styles.subheading}>
            A custom plan solves <em>your</em> lawn issues.
          </p>
          <p className={styles.iWantTo}>I want to...</p>
          <p className={styles.disclaimer}>
            Select your top issue <br className={styles.mobileOnly} />
          </p>
          <div className={styles.buttonGroup}>
            {Object.values(START_WITH_PROBLEM_OPTIONS).map((option) => (
              <ImageButton
                imageSrc={option.buttonImageSrc}
                imageAlt={option.imageAlt}
                label={option.label}
                value={option.value}
                onClick={handleClick}
              />
            ))}
          </div>
        </div>
        <VideoPlayer />
      </div>
    </FullWidthSection>
  );
};

const ImageButton = ({ imageSrc, imageAlt, label, value, onClick }) => {
  const handleClick = () => {
    onClick(value);
  };
  return (
    <button onClick={handleClick} className={styles.imageButton}>
      <img src={imageSrc} alt={imageAlt} width="94" height="94" />
      <p>{label}</p>
    </button>
  );
};

const VideoPlayer = () => {
  const [videoPlaying, setVideoPlaying] = useState(true);
  const videoRef = useRef(null);
  const controlsRef = useRef(null);

  // Enable default controls but hide them if browser supports custom
  useEffect(() => {
    const supportsVideo = !!document.createElement('video').canPlayType;

    if (supportsVideo && videoRef?.current) {
      videoRef.current.controls = false;
      controlsRef.current.style.display = 'inline-block';
    }
  }, [controlsRef, videoRef]);

  const videoHandler = () => {
    if (!videoPlaying) {
      videoRef.current.play();
      setVideoPlaying(true);
    } else if (videoPlaying) {
      videoRef.current.pause();
      setVideoPlaying(false);
    }
  };

  return (
    <div className={styles.videoContainer}>
      <video
        autoPlay
        className={styles.socialReviewVideo}
        controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
        loop
        muted
        playsInline
        poster="/images/home-page/social-before-after-poster.jpg"
        ref={videoRef}
        onPlay={() => {
          analyticsBeacon.emit(
            DeprecatedEventType.PLAY_VIDEO,
            'Social Before/After'
          );
          analyticsBeacon.emit(EventType.VIDEO_PLAYED, 'Social Before/After');
        }}
      >
        <source src="/videos/social-before-after-video.mp4" type="video/mp4" />
      </video>
      <Button
        className={styles.videoControls}
        onClick={() => videoHandler(videoPlaying)}
        ref={controlsRef}
        style={{ display: 'none' }}
      >
        <img
          alt="Play-Pause button"
          className={styles.controlsIcon}
          height="30"
          width="30"
          src={
            videoPlaying ? '/icons/pause-button.svg' : '/icons/play-button.svg'
          }
        />
      </Button>
    </div>
  );
};

export default ImageButtonsStartWithProblemSection;
